import React, {useState} from "react";
import {LoaderSvg} from "#/src/components/LoaderSvg";
import styled from "@emotion/styled";

const Button = styled.button`
  height: 48px;
`;

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<any>
}

export function LoadableButton({onClick, children, ...otherProps}: Props) {
  const [loading, setLoading] = useState(false);
  if (loading) {
    return <Button type={"button"} disabled {...otherProps} onClick={void 0}>
      {children} <LoaderSvg className={"ms-1"}/>
    </Button>
  }
  return <button type={"button"} onClick={(e) => {
    setLoading(true);
    onClick(e).then(() => setLoading(false)).catch(() => setLoading(false));
  }} {...otherProps}>{children}</button>
}