export interface ApiIdentifiable {
  id: string
}

export interface ApiCreationDateSortable {
  creationDate: number
}

export interface ApiDisplayPrioritySortable {
  displayPriority: number
}

export interface ApiGenericPageData {
  navbarData: {
    streamers: ApiStreamer[],
    streamerCategories: ApiStreamerCategory[],
    videos: ApiVideo[],
    videoCategories: ApiVideoCategory[],
    options: ApiNavbarOption[]
  },
  footerData: {
    discordUrl: string,
    socials: ApiFooterSocial[]
    streamerCategories: ApiStreamerCategory[],
    videoCategories: ApiVideoCategory[],
    articleCategories: ApiArticleCategory[]
  },
  adData: ApiAdBanner[]
}

export interface ApiFooterSocial extends ApiIdentifiable, ApiDisplayPrioritySortable {
  name: string,
  externalLink: string,
  svgCode: string
}

export interface ApiAdBanner extends ApiIdentifiable {
  campaignId: string,
  alt: string,
  description: string,
  theme: "DARK" | "LIGHT",
  placement: ApiAdCampaignPlacement,
  campaignUrl: string
}

export enum ApiAdCampaignPlacement {
  FULL_PAGE_LEFT = "FULL_PAGE_LEFT", FULL_PAGE_RIGHT = "FULL_PAGE_RIGHT",
  LOGIN_PAGE = "LOGIN_PAGE",
  ARTICLE = "ARTICLE",
  LIVE_AND_STREAMS = "LIVE_AND_STREAMS",
  BLOG_POST_PROMO = "BLOG_POST_PROMO"
}

export interface ApiNavbarOption extends ApiIdentifiable, ApiDisplayPrioritySortable {
  name: string,
  url: string | null,
  optionType: ApiNavbarOptionType,
  lightColor: string | null,
  darkColor: string | null,
}

export enum ApiNavbarOptionType {
  MAIN_PAGE = "MAIN_PAGE", LIVE_PAGE = "LIVE_PAGE", BLOG_PAGE = "BLOG_PAGE", VIDEOS_PAGE = "VIDEOS_PAGE", EXTERNAL_LINK = "EXTERNAL_LINK"
}

export interface ApiHomeData extends ApiGenericPageData {
  latestArticle: ApiArticle,
  freshArticles: ApiArticle[],
  hotArticles: ApiArticle[],
  oldArticles: ApiArticle[],
  promotedArticles: ApiArticle[],
  latestVideos: ApiVideo[],
  shoutboxMessages: ApiShoutboxMessage[],
  shoutboxRules: string,
  shoutboxChatRules: string,
}

export interface ApiBlogPostData extends ApiGenericPageData {
  article: ApiArticle
  comments: ApiArticleComment[],
  hotArticles: ApiArticle[],
  relatedArticles: ApiArticle[]
}

export interface ApiVideosData extends ApiGenericPageData {
  latestVideos: ApiVideo[],
  promotedVideos: ApiVideo[],
  oldVideos: ApiVideo[],
  categories: ApiVideoCategory[]
}

export interface ApiProfileUserData extends ApiGenericPageData {
  user: ApiProfileUser,
  selfStream: ApiUserSelfStream
}

export interface ApiLivestreamData extends ApiGenericPageData {
  streamer: ApiStreamer
}

export interface ApiLiveData extends ApiGenericPageData {
  streamers: ApiStreamer[],
  categories: ApiStreamerCategory[]
}

export interface ApiSelfStreamData extends ApiGenericPageData {
  user: ApiProfileUser,
  selfStream: ApiUserSelfStream
}

export interface ApiShoutboxMessage extends ApiIdentifiable, ApiCreationDateSortable {
  message: string,
  author: ApiSimpleUser,
  pendingModeration: boolean
}

export interface ApiArticleComment extends ApiIdentifiable, ApiCreationDateSortable {
  message: string,
  author: ApiSimpleUser,
  replyTo: string | null,
  reported: boolean,
  pendingModeration: boolean
}

export interface ApiBlogData extends ApiGenericPageData {
  latestArticle: ApiArticle,
  freshArticles: ApiArticle[],
  hotArticles: ApiArticle[],
  oldArticles: ApiArticle[],
  categories: ApiArticleCategory[]
}

export interface ApiArticle extends ApiIdentifiable, ApiCreationDateSortable {
  title: string,
  description: string,
  ogDescription: string,
  slug: string,
  author: ApiSimpleUser,
  commentsModerated: boolean,
  authorSocials: ApiUserIntegration[],
  category: ApiArticleCategory,
  isAd?: boolean
}

export interface ApiArticleCategory {
  name: string,
  slug: string,
  active: boolean
}

export interface ApiVideo extends ApiIdentifiable, ApiCreationDateSortable {
  title: string,
  thumbnailUrl: string,
  category: ApiVideoCategory,
  url: string,
  embed: string,
  views: number,
  likes: number,
  liked: boolean,
  pendingModeration: boolean,
  author: ApiSimpleUser
}

export interface ApiVideoCategory extends ApiIdentifiable {
  name: string,
  slug: string,
  visible: boolean
}

export interface ApiStreamer extends ApiIdentifiable {
  username: string,
  shortDescription: string,
  slug: string,
  isLive: boolean,
  isPromoted: boolean
  viewers: number,
  followed: boolean,
  donationLink: string,
  subscriptionLink: string,
  facebookEmbed: string,
  liveEmbed: string,
  chatEmbed: string,
  liveTitle: string,
  gameName: string,
  game: ApiStreamerGame | null,
  youTubeVideos: ApiStreamerYouTubeVideo[]
  category: ApiStreamerCategory,
  socials: ApiStreamerSocial[],
  description: ApiStreamerDescription[],
  faq: ApiStreamerFaq[]
}

export interface ApiStreamerYouTubeVideo extends ApiIdentifiable {
  title: string,
  thumbnailUrl: string,
  videoId: string,
  views: number
}

export interface ApiStreamerGame extends ApiIdentifiable {
  gameName: string
}

export interface ApiStreamerSocial extends ApiIdentifiable, ApiDisplayPrioritySortable {
  type: ApiStreamerSocialType
  url: string,
}

export enum ApiStreamerSocialType {
  TWITCH = "TWITCH",
  KICK = "KICK",
  YOUTUBE = "YOUTUBE",
  TIKTOK = "TIKTOK",
  FACEBOOK = "FACEBOOK",
  FACEBOOK_GROUP = "FACEBOOK_GROUP",
  DISCORD = "DISCORD",
  TWITTER = "TWITTER",
  INSTAGRAM = "INSTAGRAM"
}

export interface ApiStreamerCategory extends ApiIdentifiable, ApiDisplayPrioritySortable {
  name: string
}

export interface ApiStreamerDescription extends ApiIdentifiable, ApiDisplayPrioritySortable {
  title: string,
  description: string,
  isImageSet: boolean
}

export interface ApiStreamerFaq extends ApiIdentifiable, ApiDisplayPrioritySortable {
  title: string,
  description: string
}

export interface ApiSimpleUser extends ApiIdentifiable {
  username: string,
  bio: string,
  deleted: boolean,
  permission: ApiUserPermission
  deletionScheduled: boolean,
  avatarId: string
}

export interface ApiProfileUser extends ApiSimpleUser {
  login: string | null,
  selfStreamLocked: boolean,
  oauthRegistered: boolean | null,
  commentsPeek: ApiArticleCommentPeek[],
  messagesPeek: ApiShoutboxMessage[],
  videosPeek: ApiVideo[],
  integrations: ApiUserIntegration[],
  twoFactorMethods: ApiTwoFactorMethod[] | null
}

export interface ApiTwoFactorMethod extends ApiIdentifiable {
  type: "TOTP" | "BACKUP_CODES"
}

export interface ApiUserSelfStream extends ApiIdentifiable {
  login: string,
  slug: string,
  subscribeUrl: string,
  embedUrl: string,
  chatEmbedUrl: string,
  donationLink: string | null,
  liked: boolean,
  likes: number,
  enabled: boolean,
  type: "YOUTUBE" | "TWITCH"
}

export interface ApiUserIntegration extends ApiIdentifiable {
  integrationType: ApiUserIntegrationType
  displayName: string,
  visible: boolean
}

export enum ApiUserIntegrationType {
  DISCORD = "DISCORD", GOOGLE = "GOOGLE", YOUTUBE = "YOUTUBE", FACEBOOK = "FACEBOOK", INSTAGRAM = "INSTAGRAM", STEAM = "STEAM"
}

export interface ApiArticleCommentPeek extends ApiIdentifiable, ApiCreationDateSortable {
  message: string,
  author: ApiSimpleUser,
  replyTo: string | null,
  reported: boolean,
  pendingModeration: boolean,
  relatedArticle: ApiArticle
}

export enum ApiUserPermission {
  NONE = "NONE", MANAGER = "MANAGER", EDITOR = "EDITOR", MODERATOR = "MODERATOR", ADMINISTRATOR = "ADMINISTRATOR", SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR"
}

export enum ApiSortOrder {
  NEWEST = "NEWEST", OLDEST = "OLDEST"
}

export interface ApiSessionResponse {
  accessToken: string,
  refreshToken: string,
  user: ApiSimpleUser
}