import clockWhite from "#/src/assets/svg/clock-white.svg";
import React from "react";
import {useData} from "vike-react/useData";
import {ApiArticle, ApiHomeData} from "#/src/definitions/ApiDefinitions";
import {Link} from "#/src/components/Link";
import {Sort} from "#/src/utils/sort-utils";
import {truncateTitle} from "#/src/utils/string-utils";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {TimeDisplay} from "#/src/components/TimeDisplay";
import {getProfileUrl} from "#/src/utils/profile-url";
import {ClickableWrapper} from "#/src/components/ClickableWrapper";
import {navigate} from "vike/client/router";
import {DataWrapped} from "#/src/definitions/AppDefinitions";
import {AvatarWithFallback} from "#/src/components/AvatarWithFallback";

export function AdArticlesSection() {
  const {data: homeData} = useData() as DataWrapped<ApiHomeData>;
  const promotedArticles = Sort.byDate(homeData.promotedArticles);
  return <section className="special-article special-gnt my-8">
    <div className="container-xxl">
      <div className="row">
        <div className="col-xl-12">
          <div className="title-between">
            <h2 className="basic-headline">Sekcja promowana</h2>
          </div>
        </div>
      </div>
      <div className="row mt-7">
        {promotedArticles.map((article: ApiArticle) => {
          return <div key={article.id} className="col-xl-4 col-sm-6 col-md-4">
            {/* todo reuse article card but set isAd for ad custom css*/}
            <ClickableWrapper onClick={() => navigate(`/blog/${article.slug}`)}>
              <article className="article-card">
                <figure className="article-img article-img-xl">
                  <img loading={"lazy"} src={`${Attachments.getUrl(AttachmentNamespace.ARTICLE, article)}-small`} alt="#"/>
                </figure>
                <div className="article-content">
                  <div className="article-tag">
                    <a href="#" className="tag-primary">{article.category.name}</a>
                  </div>
                  <Link href={`/blog/${article.slug}`} className="article-link">{truncateTitle(article.title)}</Link>
                  <div className="article-author mt-2">
                    <div className="author">
                      <AvatarWithFallback user={article.author} className={"avatar avatar-20"} lazy/>
                      <a href={getProfileUrl(article.author)} className="author-link">{article.author.username}</a>
                    </div>
                    <div className="article-date">
                      <img src={clockWhite} alt={"Data"}/>
                      <span className="text-white ms-1"><TimeDisplay date={article.creationDate}/></span>
                    </div>
                  </div>
                </div>
              </article>
            </ClickableWrapper>
          </div>
        })}
      </div>
    </div>
  </section>
}