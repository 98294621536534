import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {useEffect} from "react";

const useAnalyticsHook = () => {
  const { user } = usePageContext() as AppPageContext;

  useEffect(() => {
    const id = user.data?.id;
    if (id) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        user_id: id,
      });
    }
  }, [user.data]);
};

export default useAnalyticsHook;