import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://656e4ff1f7f10dfd69b3419d144b5bcc@o4506875646115840.ingest.us.sentry.io/4507707109801984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.3,
  replaysOnErrorSampleRate: 0.75
});
