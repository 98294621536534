import React from "react";
import {usePageContext} from "vike-react/usePageContext";
import {Link} from "#/src/components/Link";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {NavbarRouter} from "#/src/components/navbar/NavbarRouter";
import {BrandLogo} from "#/src/components/BrandLogo";
import {NavbarThemeSwitch} from "#/src/components/navbar/NavbarThemeSwitch";
import AppConstants from "#/src/utils/app-constants";
import adminPanel from "#/src/assets/svg/admin-panel.svg";
import {AvatarWithFallback} from "#/src/components/AvatarWithFallback";

export function Navbar() {
  const {user} = usePageContext() as AppPageContext;

  function render() {
    return <nav className="navbar navbar-expand-xl">
      <div className="container-xxl">
        <div className="navbar-logo">
          <Link className="navbar-brand" href="/"><BrandLogo/></Link>
          <NavbarThemeSwitch/>
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto me-auto"><NavbarRouter/></ul>
          <div className="account d-flex gap-3 align-items-center">{renderAuthState()}</div>
        </div>
      </div>
    </nav>
  }

  function renderAuthState() {
    if (user.loggedIn) {
      return <React.Fragment>
        <AvatarWithFallback user={user.data!} className={"avatar avatar-50 avatar-none"}/>
        <Link href={"/my-profile"} className="user-name">{user.data?.username}</Link>
        {user.data?.permission != "NONE" && <a href={AppConstants.ADMIN_PANEL_URL} target={"_blank"} rel={"noreferrer noopener"} className={"btn btn-primary p-2"}>
          <img src={adminPanel} alt={"Panel administracyjny"}/>
        </a>}
      </React.Fragment>
    }
    return <React.Fragment>
      <Link href={"/login"} className="btn btn-secondary-outline">Zaloguj się</Link>
      <Link href={"/register"} className="btn btn-primary">Rejestracja</Link>
    </React.Fragment>
  }

  return render();
}