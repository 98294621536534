import React, {useRef} from "react";
import {NewestArticle} from "#/src/components/NewestArticle";
import {Swiper, SwiperSlide} from "swiper/react";
import {ArticleCard} from "#/src/components/ArticleCard";
import {ShortArticleCard} from "#/src/pages/index/ShortArticleCard";
import {Swiper as SwiperCore} from 'swiper/types';
import {useData} from "vike-react/useData";
import {ApiArticle, ApiHomeData, ApiVideo} from "#/src/definitions/ApiDefinitions";
import {NowLiveSection} from "#/src/pages/index/NowLiveSection";
import {AdArticlesSection} from "#/src/pages/index/AdArticlesSection";
import {OldArticlesSection} from "#/src/pages/index/OldArticlesSection";
import {Sort} from "#/src/utils/sort-utils";
import {Shoutbox} from "#/src/pages/index/Shoutbox";
import {VideoCard} from "#/src/components/VideoCard";
import {AppPageContext, DataWrapped} from "#/src/definitions/AppDefinitions";
import styled from "@emotion/styled";
import {usePageContext} from "vike-react/usePageContext";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {AdWrapper} from "#/src/components/AdWrapper";

interface StyledProps {
  background: string
}

const CampaignOverlayLeft = styled(AdWrapper)<StyledProps>`
  background-size: cover;
  background-position: right;
  ${props => `background-image: url('${props.background}')`};
`;

const BackgroundOverlayRight = styled(AdWrapper)<StyledProps>`
  background-size: cover;
  background-position: left;
  ${props => `background-image: url('${props.background}')`};
`;

export function Content() {
  const {data: homeData} = useData() as DataWrapped<ApiHomeData>;
  const {user} = usePageContext() as AppPageContext;
  const swiperRef = useRef<SwiperCore>();

  const freshArticles = Sort.byDate(homeData.freshArticles);
  const hotArticles = Sort.byDate(homeData.hotArticles);
  const latestVideos = Sort.byDate(homeData.latestVideos);

  const adLeft = homeData.adData
    .filter(a => a.placement == "FULL_PAGE_LEFT")
    .filter(a => a.theme == (user.lightMode ? "LIGHT" : "DARK"))[0];
  const adRight = homeData.adData
    .filter(a => a.placement == "FULL_PAGE_RIGHT")
    .filter(a => a.theme == (user.lightMode ? "LIGHT" : "DARK"))[0];
  return <React.Fragment>
    <div className={"campaign-overlay"}>
      {adLeft && <CampaignOverlayLeft ad={adLeft} background={Attachments.getUrl(AttachmentNamespace.AD, adLeft)}/>}
      {adRight && <BackgroundOverlayRight ad={adRight} background={Attachments.getUrl(AttachmentNamespace.AD, adRight)}/>}
    </div>

    <section className="front-header">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-8 col-lg-7 col-md-12"><NewestArticle article={homeData.latestArticle} size={"medium"}/></div>
          <div className="col-xl-4 col-lg-5 col-md-12">
            <Shoutbox/>
          </div>
        </div>
      </div>
    </section>

    <section className="last-article mb-5">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-12">
            <div className="title-arrow">
              <h2 className="basic-headline">Ostatnio dodane</h2>
              <div className="arrow-group ms-3">
                <button type="button" className="prev" onClick={() => swiperRef.current?.slidePrev()} aria-label={"Wstecz"}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 5L7.5 10L12.5 15" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
                <button type="button" className="next" onClick={() => swiperRef.current?.slideNext()} aria-label={"Do przodu"}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <Swiper
              direction={"horizontal"}
              loop={true}
              grabCursor={true}
              slidesPerView={"auto"}
              spaceBetween={30}
              navigation={{nextEl: ".next", prevEl: ".prev"}}
              breakpoints={{
                0: {slidesPerView: 1, spaceBetween: 10},
                320: {slidesPerView: 1.25, spaceBetween: 10},
                360: {slidesPerView: 1.5, spaceBetween: 20},
                540: {slidesPerView: 2.15, spaceBetween: 30},
                720: {slidesPerView: 2.15, spaceBetween: 30,},
                960: {slidesPerView: 3.2, spaceBetween: 30},
                1140: {slidesPerView: 3, spaceBetween: 30},
                1290: {slidesPerView: 4, spaceBetween: 30}
              }}
              onBeforeInit={(swiper) => swiperRef.current = swiper}
            >
              {freshArticles.map((article: ApiArticle) => <SwiperSlide key={article.id}><ArticleCard article={article} lazy/></SwiperSlide>)}
            </Swiper>
          </div>
        </div>
      </div>
    </section>

    <section className="news-short my-4">
      <div className="container-xxl border-space py-5">
        <h6 className="mb-4 title-small-uppercase">Warto sprawdzić</h6>
        <div className="row">{hotArticles.map((article: ApiArticle) => <ShortArticleCard key={article.id} article={article} lazy/>)}</div>
      </div>
    </section>

    <section className="video-page my-7">
      <div className="container-xxl border-b-dark">
        <div className="row">
          <div className="col-xl-12">
            <div className="title-flex mb-6">
              <h2 className="basic-headline">Filmy</h2>
              <a href={"/videos"} className="more-link">
                Zobacz więcej
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          {latestVideos.map((video: ApiVideo) => <div key={video.id} className="col-xl-4 col-sm-6 col-md-4"><VideoCard video={video} lazy/></div>)}
        </div>
      </div>
    </section>

    <NowLiveSection/>
    <AdArticlesSection/>
    <OldArticlesSection/>
  </React.Fragment>
}