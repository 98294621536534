import noAvatar from "#/src/assets/img/no-avatar.png";
import React from "react";
import {ApiSimpleUser} from "#/src/definitions/ApiDefinitions";
import {Attachments} from "#/src/utils/attachment-storage";

interface Props {
  user: ApiSimpleUser,
  className?: string,
  lazy?: boolean
}

export function AvatarWithFallback({user, className, lazy = false}: Props) {
  const onError = (event: any) => {
    event.target.onerror = null;
    event.target.src = noAvatar;
  }
  const src = user.avatarId == "default" ? noAvatar : Attachments.getUserAvatar(user);
  return <img className={className} src={src} alt={user.username} loading={lazy ? "lazy" : "eager"} onError={onError}/>
}