import React from "react";
import {ApiGenericPageData, ApiNavbarOption, ApiVideo, ApiVideoCategory} from "#/src/definitions/ApiDefinitions";
import {usePageContext} from "vike-react/usePageContext";
import {useData} from "vike-react/useData";
import {Sort} from "#/src/utils/sort-utils";
import {VideoCard} from "#/src/components/VideoCard";
import {onMobileDropdownClick} from "#/src/utils/dropdown-mobile-utils";
import {DataWrapped} from "#/src/definitions/AppDefinitions";

interface Props {
  option: ApiNavbarOption
}

export function VideosNavbarRoute({option}: Props) {
  const {data} = useData() as DataWrapped<ApiGenericPageData>;
  const {urlPathname} = usePageContext();
  function render() {
    return <li key={option.id} className="nav-item dropdown dropdown-megamenu">
      <a className={`nav-link dropdown-toggle ${urlPathname.startsWith("/videos") ? "active" : ""}`} href={"/videos"} role="button" data-bs-toggle="dropdown"
         aria-expanded="false" onClick={onMobileDropdownClick}>
        {option.name}
      </a>
      <ul className="dropdown-menu dropdown-menu-end dropdown-video">
        <div className="row">
          <div className="col-xl-2">
            <h6 className="nav-video-title">Kategorie</h6>
            <ul className="nav-video">
              {renderCategories()}
              <li className="nav-video-item">
                <a href={"/videos"} className="nav-video-link">
                  Pokaż wszystko
                </a>
              </li>
            </ul>
          </div>
          <div className="col-xl-10 video-mobile">
            <h6 className="nav-video-title">Ostatnio dodane</h6>
            <div className="row">{renderRecentVideos()}</div>
          </div>
        </div>
      </ul>
    </li>;
  }
  function renderCategories() {
    return data.navbarData.videoCategories.map((category: ApiVideoCategory) => {
      return <li key={category.id} className="nav-video-item" style={{height: "100%"}}>
        <a href={`/videos?category=${category.slug}`} className="nav-video-link">{category.name}</a>
      </li>
    });
  }
  function renderRecentVideos() {
    return Sort.byDate(data.navbarData.videos).map((video: ApiVideo) => {
      return <div key={video.id} className="col-xl-4"><VideoCard video={video} lazy={true} renderLikes={false}/></div>
    });
  }

  return render();
}