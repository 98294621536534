import '#/src/assets/scss/main.scss'
import '#/src/assets/scss/nprogress.scss'
import useTheme from "#/src/hooks/use-theme";
import {AccountDeletionWarning} from "#/src/components/AccountDeletionWarning";
import DOMPurify from "isomorphic-dompurify";
import {CookieBanner} from "#/src/components/CookieBanner";
import {ToastDisplay} from "#/src/components/ToastDisplay";
import useAnalyticsHook from "#/src/hooks/use-analytics-hook";

export {Layout}

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener');
  }
});

//global layout for scss, theme detector and other utilities
function Layout({children}) {
  useAnalyticsHook();
  useTheme();
  return <>
    <ToastDisplay/>
    <AccountDeletionWarning/>
    {children}
    <CookieBanner/>
  </>
}