export function truncateString(value: string, maxLength: number) {
  if (value.length > maxLength) {
    return value.substring(0, maxLength) + "...";
  } else {
    return value;
  }
}

export function truncateTitle(value: string) {
  return truncateString(value, 60);
}

export function formatLargeNumber(value: number): string {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(0)} mld`;
  } else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(0)} mil`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(0)} tys`;
  } else {
    return value.toString();
  }
}